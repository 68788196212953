/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/linkedBaner.png";
// import socialHead2 from "../../../images/Banner-Naccho.jpg";
import inline from "../../../images/oxfam-inline.jpg";
import leftIcon from "../../../images/Left Button.svg";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="OXFAM TRAILWALKER | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="OXFAM TRAILWALKER "
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "The OxfamTrailwalker is the ultimate team endurance event. The challenge is to walk, jog or run the 100km in under 30 hours in teams of four, with support crews to help them get to the finish line. ",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "All donations will go towards: ",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "The Gurkha Welfare Trust: to aid Gurkha veterans (soldiers from Nepal who has a lasting relationship with the British Army for over 200 years), their widows and wider communities by providing state-of-the-art medical clinics and specialist staff, access to education and natural disaster crisis management, and ",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "Oxfam: to supply, deliver, build and maintain lifesaving solar-powered water pumps, new farming techniques and portable toilets in hard-to-reach vulnerable communities, as well as providing basic hygiene kits and handwashing stations to help protect these communities from deadly viruses, e.g. COVID-19 ",
              },
            ],
            type: "paragraph",
          },
        ]}
        butlletdesign="Need"
        // statements={[
        //   {
        //     id: "",
        //     title: "SolarBuddy works towards two goals: ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "1. Gift solar devices to children living in extreme poverty",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "2. Educate and inspire people to be agents of change to end extreme energy poverty by 2030 ",
        //     heading: [],
        //   },
        // ]}
      />
      {/* <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
            heading: [],
          },
          {
            id: "",
            title:
              "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
            heading: [],
          },
        ]}
        heading={[
          {
            children: [
              {
                text: "Telehealth can deliver significant benefits including increased access to remote communities, extended capacity and reach of community-based care, and increased efficiency and decreased travel time for patients and providers. However, reliable connectivity, and digital literacy and capability is critical to ensuring successful delivery and uptake of Telehealth services. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Challenge faced by organisation "
      /> */}
      <ValuesCollection
        key=""
        title="What we did  "
        className="bg-light-blue pt-5"
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        statements={[
          {
            id: "",
            title:
              "Completed the challenge in 24 hours in three groups of four",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
        //     heading: [],
        //   },
        // ]}
        heading={[
          {
            children: [
              {
                text: "Raised a total of £3,780*, where proceeds will go towards Oxfam and The Gurkha Welfare Trust",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Impact achieved  "
      />
      {/* <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        heading={[
          {
            children: [
              {
                text: "Raised a total of £3,780*, where proceeds will go towards Oxfam and The Gurkha Welfare Trust ",
              },
            ],
            type: "paragraph",
          },
        ]}
      /> */}
      <p className="col-span-12 col-start-2 text-center p-20px ga-mt--30">
        To learn more or to contribute to this great cause, please visit &nbsp;
        <b>
          <a
            href="https://www.oxfamapps.org.uk/trailwalker/  "
            className="text-dark-blue"
          >
            https://www.oxfamapps.org.uk/trailwalker/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
